<template>
  <div class="waiting-for-draw"
       v-if="retailDrawEvent">
    <FlagCategoryTournament is-row-view
                            :event="retailDrawEvent"
                            font-size-not-default
                            :flag-size="getFlagSize"/>
    <div class="draw-id">
      {{translate('waitingForDrawNo', retailDrawEvent.lotteryDisplayId)}}
    </div>
  </div>
</template>

<script>
import FlagCategoryTournament from '@/components/Retail/FlagCategoryTournament';
import { mapGetters } from 'vuex';

export default {
  name: 'WaitingForDraw',
  components: { FlagCategoryTournament },
  computed: {
    ...mapGetters([
      'screenSize',
      'retailDrawEvent',
      'translate',
      'isResultsDataValid',
    ]),
    getFlagSize() {
      return {
        width: (this.screenSize === 1920 ? '300px' : '195px'),
        height: (this.screenSize === 1920 ? '200px' : '135px'),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.waiting-for-draw {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  .draw-id {
    color: #F3F3F3;
    font-size: 24px;
    margin-top: 54px;
    text-align: center;
    @media (min-width: 1920px) {
      font-size: 36px;
    }
  }
}
</style>
